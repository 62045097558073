<template>
  <div>
    <v-card elevation="0">
      <v-container class="pt-5">
        <v-row>
          <v-col lg="3" cols="12">
            <validation-provider v-slot="{ errors }" vid="areas">
              <autocomplete-areas v-model="area" :error-messages="errors" />
            </validation-provider>
          </v-col>

          <v-col lg="2" cols="12">
            <v-datetime-picker
              label="Data/Hora início"
              v-model="data_inicio"
              dateFormat="dd/MM/yyyy"
              timeFormat="HH:mm"
              :text-field-props="textFieldProps"
            >
            </v-datetime-picker>
          </v-col>

          <v-col lg="2" cols="12">
            <v-datetime-picker
              label="Data/Hora fim"
              v-model="data_fim"
              dateFormat="dd/MM/yyyy"
              timeFormat="HH:mm"
              :text-field-props="textFieldProps"
            >
            </v-datetime-picker>
          </v-col>

          <v-col lg="2" cols="12">
            <btn-buscar
              class="text-center"
              :temPermissao="true"
              @click="carregarLancamentoCaixaOperador()"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-row class="mt-4">
      <v-col>
        <v-data-table
          disable-filtering
          disable-sort
          :headers="cabecalho"
          :items="items"
          :options.sync="paginacao"
          :footer-props="{
            'items-per-page-options': [10, 20, 50],
            'items-per-page-text': 'Registros por página',
          }"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import relatorios from "@/api/relatorios";
export default {
  data() {
    return {
      totalRegistros: 0,
      cabecalho: [
        { text: "Login", value: "login" },
        { text: "Nome", value: "nome" },
        { text: "Tickets", value: "tickets" },
        { text: "Total", value: "total" },
      ],
      items: [],
      area: null,
      paginacao: {},
      data_inicio: new Date(),
      data_fim: new Date(),
      textFieldProps: {
        outlined: true,
        dense: true,
        with: "100px",
      },
    };
  },
  methods: {
    async carregarLancamentoCaixaOperador() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);
        const datas = {
          data_inicio: this.data_inicio,
          data_fim: this.data_fim,
          area_id: this.area,
        };
        const dados = datas;
        const resposta = await relatorios.relatorioArrecadacaoCaixaOperador(
          dados
        );
        console.log(resposta, "resposta");
        this.items = resposta.data.data;
        // console.log(this.items, "items");
        //this.totalRegistros = resposta.data.meta.total;
      } catch (e) {
        this.$snackbar.mostrar({
          cor: "error",
          mensagem: this.$erroApi._(e),
        });
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },
  },
};
</script>

<style scoped>
</style>
